import {createApp} from 'vue'
import {createI18n} from 'vue-i18n'
import {createHead} from '@vueuse/head'
import Bootstrap3 from 'bootstrap-vue-3'
import BootstrapIcon from '@dvuckovic/vue3-bootstrap-icons';
//import vSelect from 'vue-select'
import store from './store'
import router from './router'
import App from './App.vue'
import FactoryRepository from '@/api/FactoryRepository'
import '@/libs/toastification'
import JWT from '@/auth/JWT'
import helperFunctions from '@/libs/helper'
//import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

const app = createApp(App)
app.use(Bootstrap3)
app.component('BootstrapIcon', BootstrapIcon);
app.use(router)
app.use(store)
const head = createHead()
app.use(head)

/* language files */
import textEn from '@/locales/text-en.json'

const messages = {
    en: textEn
}
const i18n = createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages,
})
app.use(i18n)

const helper = {
    install() {
        app.helper = helperFunctions
        app.config.globalProperties.$helper = helperFunctions
    },
}
app.use(helper)

// JWT
const Jwt = {
    install() {
        app.JWT = JWT
        app.config.globalProperties.$JWT = JWT
    },
}
app.use(Jwt)

// API Factory
FactoryRepository.init(JWT)
const api = {
    install() {
        app.AcTypes = FactoryRepository.get('AcTypes')
        app.config.globalProperties.$AcTypes = FactoryRepository.get('AcTypes')

        app.Analytics = FactoryRepository.get('Analytics')
        app.config.globalProperties.$Analytics = FactoryRepository.get('Analytics')

        app.Banners = FactoryRepository.get('Banners')
        app.config.globalProperties.$Banners = FactoryRepository.get('Banners')

        app.Branches = FactoryRepository.get('Branches')
        app.config.globalProperties.$Branches = FactoryRepository.get('Branches')

        app.CsrStamps = FactoryRepository.get('CsrStamps')
        app.config.globalProperties.$CsrStamps = FactoryRepository.get('CsrStamps')

        app.Cwos = FactoryRepository.get('Cwos')
        app.config.globalProperties.$Cwos = FactoryRepository.get('Cwos')

        app.DeliveryItems = FactoryRepository.get('DeliveryItems')
        app.config.globalProperties.$DeliveryItems = FactoryRepository.get('DeliveryItems')

        app.UsedItems = FactoryRepository.get('UsedItems')
        app.config.globalProperties.$UsedItems = FactoryRepository.get('UsedItems')

        app.Departments = FactoryRepository.get('Departments')
        app.config.globalProperties.$Departments = FactoryRepository.get('Departments')

        app.Enums = FactoryRepository.get('Enums')
        app.config.globalProperties.$Enums = FactoryRepository.get('Enums')

        app.Files = FactoryRepository.get('Files')
        app.config.globalProperties.$Files = FactoryRepository.get('Files')

        app.Forms = FactoryRepository.get('Forms')
        app.config.globalProperties.$Forms = FactoryRepository.get('Forms')

        app.FormTypes = FactoryRepository.get('FormTypes')
        app.config.globalProperties.$FormTypes = FactoryRepository.get('FormTypes')

        app.PartRequests = FactoryRepository.get('PartRequests')
        app.config.globalProperties.$PartRequests = FactoryRepository.get('PartRequests')

        app.Directories = FactoryRepository.get('Directories')
        app.config.globalProperties.$Directories = FactoryRepository.get('Directories')

        app.Documents = FactoryRepository.get('Documents')
        app.config.globalProperties.$Documents = FactoryRepository.get('Documents')

        app.HolidayRequests = FactoryRepository.get('HolidayRequests')
        app.config.globalProperties.$HolidayRequests = FactoryRepository.get('HolidayRequests')

        app.Invoices = FactoryRepository.get('Invoices')
        app.config.globalProperties.$Invoices = FactoryRepository.get('Invoices')

        app.InvoiceCategories = FactoryRepository.get('InvoiceCategories')
        app.config.globalProperties.$InvoiceCategories = FactoryRepository.get('InvoiceCategories')

        app.Ocrs = FactoryRepository.get('Ocrs')
        app.config.globalProperties.$Ocrs = FactoryRepository.get('Ocrs')

        app.Receipts = FactoryRepository.get('Receipts')
        app.config.globalProperties.$Receipts = FactoryRepository.get('Receipts')

        app.Stations = FactoryRepository.get('Stations')
        app.config.globalProperties.$Stations = FactoryRepository.get('Stations')

        app.StockItems = FactoryRepository.get('StockItems')
        app.config.globalProperties.$StockItems = FactoryRepository.get('StockItems')

        app.StockItemLocations = FactoryRepository.get('StockItemLocations')
        app.config.globalProperties.$StockItemLocations = FactoryRepository.get('StockItemLocations')

        app.StockItemOwners = FactoryRepository.get('StockItemOwners')
        app.config.globalProperties.$StockItemOwners = FactoryRepository.get('StockItemOwners')

        app.StockItemTypes = FactoryRepository.get('StockItemTypes')
        app.config.globalProperties.$StockItemTypes = FactoryRepository.get('StockItemTypes')

        app.ToolRequests = FactoryRepository.get('ToolRequests')
        app.config.globalProperties.$ToolRequests = FactoryRepository.get('ToolRequests')

        app.TravellingOrders = FactoryRepository.get('TravellingOrders')
        app.config.globalProperties.$TravellingOrders = FactoryRepository.get('TravellingOrders')

        app.Users = FactoryRepository.get('Users')
        app.config.globalProperties.$Users = FactoryRepository.get('Users')

        app.EmailTemplates = FactoryRepository.get('EmailTemplates')
        app.config.globalProperties.$EmailTemplates = FactoryRepository.get('EmailTemplates')

        app.Emails = FactoryRepository.get('Emails')
        app.config.globalProperties.$Emails = FactoryRepository.get('Emails')

        app.ReadAndUnderstand = FactoryRepository.get('ReadAndUnderstand')
        app.config.globalProperties.$ReadAndUnderstand = FactoryRepository.get('ReadAndUnderstand')

        app.Roles = FactoryRepository.get('Roles')
        app.config.globalProperties.$Roles = FactoryRepository.get('Roles')

        app.ServiceRequestTypes = FactoryRepository.get('ServiceRequestTypes')
        app.config.globalProperties.$ServiceRequestTypes = FactoryRepository.get('ServiceRequestTypes')

        app.Pdfs = FactoryRepository.get('Pdfs')
        app.config.globalProperties.$Pdfs = FactoryRepository.get('Pdfs')

        app.Projects = FactoryRepository.get('Projects')
        app.config.globalProperties.$Projects = FactoryRepository.get('Projects')

        app.TimeTracks = FactoryRepository.get('TimeTracks')
        app.config.globalProperties.$TimeTracks = FactoryRepository.get('TimeTracks')

        app.Exports = FactoryRepository.get('Exports')
        app.config.globalProperties.$Exports = FactoryRepository.get('Exports')
    },
}
app.config.globalProperties.$filters = {
    price(num) {
        if (num) {
            return parseFloat(num).toFixed(0).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        }
        return num
    }
}
app.use(api)
app.mount('#app')
