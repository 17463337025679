import Resource from './Resource'

const apiURL = `https://${process.env.VUE_APP_API_URL}`
let repositories = {}

export default {
    init: JWT => {
        repositories = {
            AcTypes: new Resource(JWT, {apiURL, resource: 'ac_types'}),
            Analytics: new Resource(JWT, {apiURL, resource: 'stock/analytics'}),
            Banners: new Resource(JWT, {apiURL, resource: 'banners'}),
            Branches: new Resource(JWT, {apiURL, resource: 'branches'}),
            CsrStamps: new Resource(JWT, {apiURL, resource: 'csr_stamps'}),
            Cwos: new Resource(JWT, {apiURL, resource: 'cwos'}),
            DeliveryItems: new Resource(JWT, {apiURL, resource: 'delivery_items'}),
            UsedItems: new Resource(JWT, {apiURL, resource: 'used_items'}),
            Departments: new Resource(JWT, {apiURL, resource: 'departments'}),
            Enums: new Resource(JWT, {apiURL, resource: 'enums'}),
            Files: new Resource(JWT, {apiURL, resource: 'files'}),
            Forms: new Resource(JWT, {apiURL, resource: 'forms'}),
            FormTypes: new Resource(JWT, {apiURL, resource: 'form_types'}),
            HolidayRequests: new Resource(JWT, {apiURL, resource: 'holiday_requests'}),
            Invoices: new Resource(JWT, {apiURL, resource: 'invoices'}),
            InvoiceCategories: new Resource(JWT, {apiURL, resource: 'invoice_categories'}),
            Ocrs: new Resource(JWT, {apiURL, resource: 'ocrs'}),
            Receipts: new Resource(JWT, {apiURL, resource: 'receipts'}),
            Stations: new Resource(JWT, {apiURL, resource: 'stations'}),
            StockItems: new Resource(JWT, {apiURL, resource: 'stock_items'}),
            StockItemLocations: new Resource(JWT, {apiURL, resource: 'stock_item_locations'}),
            StockItemOwners: new Resource(JWT, {apiURL, resource: 'stock_item_owners'}),
            StockItemTypes: new Resource(JWT, {apiURL, resource: 'stock_item_types'}),
            ToolRequests: new Resource(JWT, {apiURL, resource: 'tool_requests'}),
            TravellingOrders: new Resource(JWT, {apiURL, resource: 'travelling_orders'}),
            Users: new Resource(JWT, {apiURL, resource: 'users'}),
            PartRequests: new Resource(JWT, {apiURL, resource: 'cwo_part_requests'}),
            EmailTemplates: new Resource(JWT, {apiURL, resource: 'email_templates'}),
            Emails: new Resource(JWT, {apiURL, resource: 'emails'}),
            Directories: new Resource(JWT, {apiURL, resource: 'directories'}),
            Documents: new Resource(JWT, {apiURL, resource: 'documents'}),
            ReadAndUnderstand: new Resource(JWT, {apiURL, resource: 'read_and_understands'}),
            Roles: new Resource(JWT, {apiURL, resource: 'roles'}),
            ServiceRequestTypes: new Resource(JWT, {apiURL, resource: 'service_request_types'}),
            Pdfs: new Resource(JWT, {apiURL, resource: 'pdfs'}),
            Projects: new Resource(JWT, {apiURL, resource: 'projects'}),
            TimeTracks: new Resource(JWT, {apiURL, resource: 'time_tracks'}),
            Exports: new Resource(JWT, {apiURL, resource: 'exports'})
        }
    },
    get: name => repositories[name],
}
