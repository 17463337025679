import moment from 'moment'
import enumsEn from '@/locales/enum-en.json'
import {getUserData} from '@/auth/utils'

const slugify = txt => {
    let str = txt
    str = str.replace(/^\s+|\s+$/g, '')

    // Make the string lowercase
    str = str.toLowerCase()

    // Remove accents, swap ñ for n, etc
    const from = 'ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;'
    const to = 'AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa______'
    for (let i = 0, l = from.length; i < l; i += 1) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
    }

    // Remove invalid chars
    str = str.replace(/[^a-z0-9 -]/g, '')
        // Collapse whitespace and replace by _
        .replace(/\s+/g, '_')
        // Collapse dashes
        .replace(/-+/g, '_')

    return str
}

const slugifyURL = txt => {
    let str = txt
    str = str.replace(/^\s+|\s+$/g, '')

    // Make the string lowercase
    str = str.toLowerCase()

    // Remove accents, swap ñ for n, etc
    const from = 'ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽĹĽľĺáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/-,:;'
    const to = 'AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZLLllaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------'
    for (let i = 0, l = from.length; i < l; i += 1) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
    }

    // Remove invalid chars
    str = str.replace(/[^a-z0-9 -]/g, '')
        // Collapse whitespace and replace by _
        .replace(/\s+/g, '-')
        // Collapse dashes
        .replace(/-+/g, '-')

    return str
}

const generateRandomString = length => {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i += 1) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
}

const showToast = (toast, toastComponent, title, text, variant) => {
    toast({
        component: toastComponent,
        position: 'top-right',
        props: {
            title,
            text,
            variant,
        },
    })
}

const getEnumTranslation = (name, key) => {
    let enums = enumsEn
    if (enums[name]) {
        if (enums[name][key]) {
            return enums[name][key]
        }
    } else if (enums[key]) {
        return enums[key]
    }
    return null
}

const getAddUrl = (root) => {
    return `/${root}/add`;
}

const getDetailUrl = (root, id) => {
    return `/${root}/${id}`;
}

const getEditUrl = (root, id) => {
    return `/${root}/${id}/edit`;
}

const getViewUrl = (root, id) => {
    return `/${root}/${id}/view`;
}

const getCountriesList = () => {
    return ["Afghan",
        "Albanian",
        "Algerian",
        "Argentinian",
        "Armenian",
        "Australian",
        "Austrian",
        "Bangladeshi",
        "Belgian",
        "Bolivian",
        "Batswana",
        "Brazilian",
        "Bulgarian",
        "Cambodian",
        "Cameroonian",
        "Canadian",
        "Chilean",
        "Chinese",
        "Colombian",
        "Costa Rican",
        "Croatian",
        "Cuban",
        "Czech",
        "Danish",
        "Dominican",
        "Ecuadorian",
        "Egyptian",
        "Salvadorian",
        "English",
        "Estonian",
        "Ethiopian",
        "Fijian",
        "Finnish",
        "French",
        "German",
        "Ghanaian",
        "Greek",
        "Guatemalan",
        "Haitian",
        "Honduran",
        "Hungarian",
        "Icelandic",
        "Indian",
        "Indonesian",
        "Iranian",
        "Iraqi",
        "Irish",
        "Israeli",
        "Italian",
        "Jamaican",
        "Japanese",
        "Jordanian",
        "Kenyan",
        "Kuwaiti",
        "Lao",
        "Latvian",
        "Lebanese",
        "Libyan",
        "Lithuanian",
        "Macedonian",
        "Malagasy",
        "Malaysian",
        "Malian",
        "Maltese",
        "Mexican",
        "Mongolian",
        "Moldavian",
        "Moroccan",
        "Mozambican",
        "Namibian",
        "Nepalese",
        "Dutch",
        "New Zealand",
        "Nicaraguan",
        "Nigerian",
        "Norwegian",
        "Pakistani",
        "Panamanian",
        "Paraguayan",
        "Peruvian",
        "Philippine",
        "Polish",
        "Portuguese",
        "Romanian",
        "Russian",
        "Saudi",
        "Scottish",
        "Senegalese",
        "Serbian",
        "Singaporean",
        "Slovak",
        "South African",
        "Korean",
        "Spanish",
        "Sri Lankan",
        "Sudanese",
        "Swedish",
        "Swiss",
        "Syrian",
        "Taiwanese",
        "Tajikistani",
        "Thai",
        "Tongan",
        "Tunisian",
        "Turkish",
        "Ukrainian",
        "Emirati",
        "British",
        "American",
        "Uruguayan",
        "Venezuelan",
        "Vietnamese",
        "Welsh",
        "Zambia"]
}

const formatDate = date => {
    return date === null || date === undefined ? '' : moment(date).format('DD.MM.YYYY')
}

const formatTime = date => {
    return date === null || date === undefined ? '' : moment(date).format('HH:mm')
}

const formatDateTime = date => {
    return date === null || date === undefined ? '' : moment(date).format('DD.MM.YYYY HH:mm')
}

const formatDateTimeToUTC = (dateTime) => {
    // If we convert to Date object, it will be converted to local time (instead of UTC), which is not what we want. If we convert to UTC, we lose a few hours, because users do not want to use local time. It is a tricky situation.
    let dateTimeString = JSON.stringify(dateTime);
    let formattedDateTime = dateTimeString.replace('T', ' ').slice(1, -10);
    return formattedDateTime;
}

const formatDateTimeToSend = date => {
    let year = new Date(date).getFullYear()
    let month = new Date(date).getMonth() + 1
    let day = new Date(date).getDate()
    let hours = new Date(date).getHours()
    let minutes = new Date(date).getMinutes()
    let seconds = new Date(date).getSeconds()
    month = ((month < 10) ? '0' : '') + month
    day = ((day < 10) ? '0' : '') + day
    hours = ((hours < 10) ? '0' : '') + hours
    minutes = ((minutes < 10) ? '0' : '') + minutes
    seconds = ((seconds < 10) ? '0' : '') + seconds
    return date === '' || date === null || date === undefined ? date : year + '-' + month + '-' + day + 'T' + hours + ':' + minutes + ':' + seconds
}

const userCapability = (action, route) => {
    let userData = getUserData()
    if (userData) {
        if (userData.role === 'ROLE_ADMIN') {
            return true
        }
        if (typeof route === 'object' && route.meta.permission) {
            const splitTo = route.meta.permission.split('.')
            if (route.meta.permission === 'user.profile') {
                return true
            }
            if (route.meta.permission === 'documents.list' && (action === 'create' || action === 'update')) {
                return userData.permissions['documents'] && userData.permissions['documents'][action]
            }
            if (userData.permissions[splitTo[0]] && userData.permissions[splitTo[0]][action]) {
                return true
            }
        }
        if (typeof route === 'string' || route instanceof String) {
            if (userData.permissions[route] && userData.permissions[route][action]) {
                return true
            }
        }
    }
    return false
}
const userAdditionalCapability = (action) => {
    let userData = getUserData()
    if (userData.role === 'ROLE_ADMIN') {
        return true
    }
    if (userData.additionalPermissions[action].value) {
        return true
    }
    return false
}

const userAdditionalRole = (roleId) => {
    let userData = getUserData()
    let result = false
    userData.permissionRoles.forEach(element => {
        console.log(element.id)
        if (element.id == roleId) {
            result = true
        }
    })
    return result
}

const getUserMainRole = () => {
    let userData = getUserData()
    return userData.role
}

export default {
    slugify,
    slugifyURL,
    showToast,
    getEnumTranslation,
    generateRandomString,
    formatDate,
    formatTime,
    formatDateTime,
    formatDateTimeToUTC,
    formatDateTimeToSend,
    getAddUrl,
    getDetailUrl,
    getEditUrl,
    getViewUrl,
    getCountriesList,
    userCapability,
    userAdditionalCapability,
    userAdditionalRole,
    getUserMainRole
}
