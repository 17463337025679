import JWT from './JWT'

export const isUserLoggedIn = () => localStorage.getItem('userData') && localStorage.getItem(JWT.config.storageTokenKeyName)

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const getHomeRouteForLoggedInUser = userRole => {
    if (userRole === 'ROLE_ADMIN') return `/`
    if (userRole === 'ROLE_CUSTOMER') return `/`
    if (userRole === 'ROLE_EMPLOYEE') return `/`
    return {name: 'login'}
}
