import AbstractResource from './AbstractResource'
import SerializationGroups from './SerializationGroups.json'

export default class Resource extends AbstractResource {
    constructor(JWT, config) {
        super()
        this.apiURL = config.apiURL
        this.resource = config.resource
        this.JWT = JWT
    }

    prepareURL() {
        return `${this.apiURL}/${this.resource}`
    }

    getName() {
        return `${this.resource}`
    }

    expandSerializationGroup(parameters, serializationGroups, group, relations = '') {
        if (Array.isArray(group)) {
            parameters['properties'] = group
        }
        if (serializationGroups[group]) {
            let columns = [];
            serializationGroups[group].forEach((property) => {
                if (property instanceof Object) {
                    this.expandSerializationGroup(
                        parameters,
                        property,
                        Object.keys(property)[0],
                        relations + '[' + Object.keys(property)[0] + ']'
                    )
                } else {
                    columns.push(property);
                }
            });
            relations ? parameters['properties' + relations] = columns : parameters['properties'] = columns;
        }
    }

    getCollection(parameters, serializationGroup = null) {
        const {params = {}, headers = {}} = parameters
        const url = this.prepareURL(parameters)
        if (serializationGroup !== null) {
            this.expandSerializationGroup(params, SerializationGroups, serializationGroup)
        }
        return this.JWT.axiosIns.get(url, {params, headers})
    }

    getResource(parameters, serializationGroup = null) {
        const {id, params = {}, headers = {}} = parameters
        const url = this.prepareURL(parameters)
        if (serializationGroup !== null) {
            this.expandSerializationGroup(params, SerializationGroups, serializationGroup)
        }
        return this.JWT.axiosIns.get(`${url}/${id}`, {params, headers})
    }

    createResource(parameters) {
        const {body, params = {}, headers = {}} = parameters
        const url = this.prepareURL(parameters)
        return this.JWT.axiosIns.post(url, body, {params, headers})
    }

    updateResource(parameters) {
        const {
            id, body, params = {}, headers = {},
        } = parameters
        const url = this.prepareURL(parameters)
        headers['Content-Type'] = 'application/merge-patch+json'
        return this.JWT.axiosIns.patch(`${url}/${id}`, body, {params, headers})
    }

    deleteResource(parameters) {
        const {id, params = {}, headers = {}} = parameters
        const url = this.prepareURL(parameters)
        return this.JWT.axiosIns.delete(`${url}/${id}`, {params, headers})
    }

    getResourceFiles(parameters) {
        const {id, params = {}, headers = {}} = parameters
        const url = this.prepareURL(parameters)
        return this.JWT.axiosIns.get(`${url}/${id}/files`, {params, headers})
    }

    getResourceUsers(parameters, serializationGroup = null) {
        const {id, params = {}, headers = {}} = parameters
        const url = this.prepareURL(parameters)
        if (serializationGroup !== null) {
            this.expandSerializationGroup(params, SerializationGroups, serializationGroup)
        }
        return this.JWT.axiosIns.get(`${url}/${id}/users`, {params, headers})
    }

    getResourceByUrl(parameters, serializationGroup = null) {
        const {url, params = {}, headers = {}} = parameters
        if (serializationGroup !== null) {
            this.expandSerializationGroup(params, SerializationGroups, serializationGroup)
        }
        return this.JWT.axiosIns.get(`${this.apiURL}${url}`, {params, headers})
    }

    createResourceByUrl(parameters) {
        const {url, body = {}, params = {}, headers = {}} = parameters
        return this.JWT.axiosIns.post(`${this.apiURL}${url}`, body, {params, headers})
    }

    updateResourceByUrl(parameters) {
        const {
            url, body, params = {}, headers = {},
        } = parameters
        headers['Content-Type'] = 'application/merge-patch+json'
        return this.JWT.axiosIns.patch(`${this.apiURL}${url}`, body, {params, headers})
    }

    deleteResourceByUrl(parameters) {
        const {url, params = {}, headers = {}} = parameters
        return this.JWT.axiosIns.delete(`${this.apiURL}${url}`, {params, headers})
    }
}
